import { template as template_9329ccc15c98465a804152f9b24e3e06 } from "@ember/template-compiler";
const FKLabel = template_9329ccc15c98465a804152f9b24e3e06(`
  <label for={{@fieldId}} ...attributes>
    {{yield}}
  </label>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default FKLabel;
