import { template as template_df0eed1d58534ff99c9fb582bd307ad6 } from "@ember/template-compiler";
const FKText = template_df0eed1d58534ff99c9fb582bd307ad6(`
  <p class="form-kit-text" ...attributes>
    {{yield}}
  </p>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default FKText;
