import { template as template_71e907013cef403a9b97c64b2d61272c } from "@ember/template-compiler";
const FKControlMenuContainer = template_71e907013cef403a9b97c64b2d61272c(`
  <li class="form-kit__control-menu-container">
    {{yield}}
  </li>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default FKControlMenuContainer;
